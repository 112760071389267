// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DollarOutlined,
  LoginOutlined,
  PhoneOutlined,
  RocketOutlined,
  ZoomInOutlined,
  DashboardOutlined,
  ApiOutlined,
  SettingOutlined
} from '@ant-design/icons';


// icons
const icons = { DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined, ZoomInOutlined, DashboardOutlined, ApiOutlined, SettingOutlined};

// ==============================|| MENU ITEMS - PAGES ||============================== //

const menupath = {
  id: 'group-menu',
  title: <FormattedMessage id="menu" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined
    },
    {
      id: 'charging sesion',
      title: <FormattedMessage id="ประวัติการชาร์จ" />,
      type: 'item',
      url: '/chargingsession',
      icon: icons.ApiOutlined
    },
    // {
    //   id: 'Transfer Payment',
    //   title: <FormattedMessage id="รายการโอนเงิน" />,
    //   type: 'item',
    //   url: '/transferpayment',
    //   icon: icons.DollarOutlined
    // },
    {
      id: 'info',
      title: <FormattedMessage id="ข้อมูลผู้ใช้ และ การถอนเงิน" />,
      type: 'item',
      url: '/info',
      icon: icons.ZoomInOutlined
    },
    {
      id: 'settingvalue',
      title: <FormattedMessage id="ตั้งค่าCharger" />,
      type: 'item',
      url: '/settingvalue',
      icon: icons.SettingOutlined
    },
  ]
};

export default menupath;
